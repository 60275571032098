import React from "react";
import "../../Assets/Styles/Spinner.css";
import { Box } from "@mui/material";

function Spinner() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <div class="loader"></div>
    </Box>
  );
}

export default Spinner;
