import { React, Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import Spinner from "./Components/Common/Spinner";
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";

const LazyApp = lazy(() => import("./App"));

const animationVariants = {
  hidden: { opacity: 0, y: 0 },
  visible: { opacity: 1, y: 0 },
};

const root = ReactDOM.createRoot(document.getElementById("main"));

root.render(
  <Suspense fallback={<Spinner />}>
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
      />
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={animationVariants}
        transition={{ duration: 0.3 }}
      >
        <LazyApp />
      </motion.div>
    </>
  </Suspense>
);
